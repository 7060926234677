import React, { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  logout,
  useAuthState,
  useAuthDispatch,
} from "/app/src/contexts/authentication";

import logo from "./logo.svg";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { Button, Menu, Row, Col, Popover } from "antd";
import { MenuOutlined, GlobalOutlined, UserOutlined } from "@ant-design/icons";
import { useToggle } from "/app/src/hooks";
import { userService } from "/app/src/services";

export default function Header() {
  const navigate = useNavigate();

  const dispatch = useAuthDispatch(); // read dispatch method from context
  const { message, loading } = useStatusContext();
  const [toggled, setToggled] = useToggle();
  const { t, i18n } = useTranslation();
  const { user, isAuthenticated } = useAuthState(); //read the values of loading and errorMessage from context

  const changeLanguage = useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
      if (user?.id) {
        userService.updateSingle(user.id, { language: lng });
      }
    },
    [i18n, user?.id],
  );
  const setLanguageFrench = useCallback(() => {
    changeLanguage("fr");
  }, [changeLanguage]);
  const setLanguageEnglish = useCallback(() => {
    changeLanguage("en");
  }, [changeLanguage]);

  const menuItems = [
    {
      key: 1,
      label: <NavLink to="/dashboard">{t("translation:dashboard")}</NavLink>,
    },
    {
      key: 2,
      label: <NavLink to="/apps">{t("translation:apps")}</NavLink>,
    },
    {
      key: 3,
      label: <NavLink to="/explorer">{t("translation:explorer")}</NavLink>,
    },
    {
      key: 4,
      label: <NavLink to="/reports">{t("translation:reports")}</NavLink>,
    },
    {
      key: 5,
      label: <NavLink to="/workflows">{t("translation:workflows")}</NavLink>,
    },
    {
      key: 6,
      label: <NavLink to="/users">{t("translation:users")}</NavLink>,
    },
    {
      key: 7,
      label: <>{t("translation:log_out")}</>,
      onClick: () => {
        handleLogout();
      },
    },
  ];

  const handleLogout = useCallback(() => {
    logout(dispatch); //call the logout action
    navigate("/login"); //navigate to logout page on logout
  }, [dispatch, navigate]);

  /**
   * Navigate to the user profile page
   */
  const handleProfileClick = useCallback(() => {
    navigate(`/users/${user.id}`);
  }, [navigate, user]);

  if (loading) {
    return null;
  } else {
    return (
      <div className="header">
        {isAuthenticated &&
          !["invalid", "locked", "restarting"].includes(message) && (
            <>
              <Row>
                <Col className="logo">
                  <img src={logo} alt="ItemPath" height="38" />
                </Col>
                <Col className="menu">
                  <nav className="links">
                    <NavLink to="/dashboard">
                      {t("translation:dashboard")}
                    </NavLink>
                    <NavLink to="/apps">{t("translation:apps")}</NavLink>
                    <NavLink to="/explorer">
                      {t("translation:explorer")}
                    </NavLink>
                    <NavLink to="/reports">{t("translation:reports")}</NavLink>
                    <NavLink to="/workflows">
                      {t("translation:workflows")}
                    </NavLink>
                    <NavLink to="/users">{t("translation:users")}</NavLink>
                  </nav>
                </Col>
                <Col>
                  <Row className="userMenu">
                    <Col>
                      <UserOutlined
                        className="hide-mobile headerIcon"
                        onClick={handleProfileClick}
                      />
                    </Col>
                    <Col>
                      <Popover
                        placement="bottomRight"
                        content={
                          <>
                            <Button
                              style={{
                                display: "block",
                                width: "100%",
                                marginBottom: "5px",
                              }}
                              onClick={setLanguageEnglish}
                            >
                              English
                            </Button>
                            <Button
                              style={{ display: "block", width: "100%" }}
                              onClick={setLanguageFrench}
                            >
                              Français
                            </Button>
                          </>
                        }
                        trigger="click"
                      >
                        <GlobalOutlined className="hide-mobile headerIcon" />
                      </Popover>
                    </Col>
                    <Col style={{ paddingLeft: "10px" }}>
                      <MenuOutlined
                        className="menu-button"
                        onClick={setToggled}
                        style={{ color: "#82878e", marginTop: "5px" }}
                      />
                      <Button
                        type="ghost"
                        size="large"
                        className="hide-mobile"
                        onClick={handleLogout}
                      >
                        {t("translation:log_out")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className={toggled ? "vertical-menu" : "hidden"}>
                <Menu
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="vertical"
                  onClick={setToggled}
                  items={menuItems}
                />
              </div>
            </>
          )}
      </div>
    );
  }
}
