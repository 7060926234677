import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Descriptions } from "antd";

import { costCenterService } from "/app/src/services";
import { useTranslation } from "react-i18next";

import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { CostCenter as CostCenterType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
export default function CostCenter() {
  const { t } = useTranslation();
  const { costCenterId } = useParams();

  // Get the Single Cost Center from URL
  const { data: costCenter } = useQuery({
    queryKey: ["costCenter", costCenterId],
    queryFn: () => {
      return costCenterService.getSingle(costCenterId);
    },
    initialData: { cost_center: {} },
    select: (data: { cost_center: CostCenterType }) => {
      return data.cost_center;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{costCenter?.name}</title>
      </Helmet>
      {costCenter?.name ? (
        <div>
          <div className="box">
            <h1>{costCenter?.name}</h1>
            <div className="bigItems">
              <div className="bigItem">
                <h4>{t("translation:created")}</h4>
                <span>
                  <PowerPickTimeZoneToLocal
                    date={costCenter?.creationDate}
                    format={"MMM YYYY"}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="box">
            <h3>{t("translation:information_fields")}</h3>
            <Descriptions>
              <Descriptions.Item label={t("translation:description")}>
                {costCenter?.description}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
