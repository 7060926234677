import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Input, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";

import { settingService, statusService } from "/app/src/services";
import { settingsSchema } from "/app/src/schemas/settingsSchema";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * Component to display the forms for updating the license
 */
export default function License() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Count of Rows
  const { data: license } = useQuery({
    queryKey: ["license"],
    queryFn: () => {
      return statusService.getLicense();
    },
    initialData: { license: "" },
    select: (data: { license: string }) => {
      return data.license;
    },
  });
  //useMutation for updating the license
  const { mutateAsync: updateLicense } = useMutation({
    mutationFn: (values: { license: string }) => {
      return settingService
        .updateSettings({ license: values.license })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.license = values.license;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["license"], { license: data.license });
    },
  });

  //onSubmit handler for updating the license
  const onSubmitLicense = useCallback(
    async (
      {
        license,
      }: {
        license: string;
      },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      await updateLicense({ license }).catch((error) => {
        return setFieldError("license", error.errors[0]);
      });
    },
    [updateLicense],
  );

  /**
   * Update license form
   */
  const updateLicenseForm: (
    props: FormikProps<{ license: string }>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3>{t("translation:license")}</h3>
            <p>{t("translation:license_description")}</p>
          </Col>
          <Col span={16}>
            <Form.Item name="license">
              <Input.TextArea
                className="no-label" // skipcq: JS-0394
                name="license"
                rows={4}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={16} offset={8}>
            <SubmitButton type="primary" size="large" block disabled={!dirty}>
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );
  return (
    <Formik
      component={updateLicenseForm}
      initialValues={{
        license,
      }}
      validationSchema={settingsSchema}
      enableReinitialize
      onSubmit={onSubmitLicense}
    />
  );
}
