import React, { useCallback } from "react";
import { PageHeader, Radio, Select } from "antd";
import barIcon from "./barIcon.svg";
import pieIcon from "./pieIcon.svg";
import lineIcon from "./lineIcon.svg";
import numberIcon from "./numberIcon.svg";
import calendarIcon from "./calendarIcon.svg";
import stackedBarIcon from "./stackedBarIcon.svg";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { Widget } from "/app/src/models";
import Title from "./title";
import Datasets from "./datasets";

import TrendLines from "./trendlines";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { widgetService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";

export default function EditWidget({ widget }: { widget: Widget }) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] =
    React.useState<string>("datasets");
  const queryClient = useQueryClient();
  const { mutateAsync: updateWidget } = useMutation({
    mutationFn: (values: Omit<Widget, "datasets">) => {
      return widgetService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["widgets", widget.viewId],
        (oldData: { widgets: Widget[] }) => {
          return {
            widgets: oldData.widgets.map((widget) => {
              if (widget.id === response.widget.id) {
                return response.widget;
              }
              return widget;
            }),
          };
        },
      );
    },
  });

  const { mutateAsync: deleteWidget } = useMutation({
    mutationFn: (id: number) => {
      return widgetService.deleteSingle(id).then(handlePromiseError);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["widgets", widget.viewId],
        (oldData: { widgets: Widget[] }) => {
          return {
            widgets: oldData.widgets.filter((w) => {
              return w.id !== widget.id;
            }),
          };
        },
      );
    },
  });

  const handleDeleteClick = useCallback((): void => {
    deleteWidget(widget.id);
  }, [deleteWidget, widget.id]);

  const handleChartTypeChange = useCallback(
    (e) => {
      updateWidget({ id: widget.id, chartType: e.target.value });
      //on chart change we need to refresh the data
      queryClient.invalidateQueries(["widgetData", widget.id]);
    },
    [queryClient, updateWidget, widget.id],
  );

  /**
   * Updates the selected option: Either "datasets" or "trendlines"
   * @param value the value of the selected option
   */
  const updateSelectedOption = useCallback((value: string) => {
    setSelectedOption(value);
  }, []);
  return (
    <div className="editWidget">
      <PageHeader
        ghost={false}
        title={<Title widget={widget} updateWidget={updateWidget} />}
        extra={<DeleteButton onClick={handleDeleteClick} />}
      />
      <div className="chartType">
        <Radio.Group
          defaultValue={widget.chartType}
          value={widget.chartType}
          name="chartType"
          onChange={handleChartTypeChange}
        >
          <Radio.Button value="line">
            <img className="icon" src={lineIcon} alt="line" />
          </Radio.Button>
          <Radio.Button value="bar">
            <img className="icon" src={barIcon} alt="bar" />
          </Radio.Button>
          <Radio.Button value="stackedBar">
            <img className="icon" src={stackedBarIcon} alt="stackedBar" />
          </Radio.Button>
          <Radio.Button value="pie">
            <img className="icon" src={pieIcon} alt="pie" />
          </Radio.Button>
          <Radio.Button value="number">
            <img className="icon" src={numberIcon} alt="number" />
          </Radio.Button>
          <Radio.Button value="calendar">
            <img className="icon" src={calendarIcon} alt="calendar" />
          </Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ float: "right" }}>
        <Select
          size="large"
          onChange={updateSelectedOption}
          value={selectedOption}
          bordered={false}
        >
          <Select.Option value="datasets">
            {t("translation:snapshots")}
          </Select.Option>
          {["bar", "stackedBar", "line"].includes(widget.chartType) && (
            <Select.Option value="trendlines">
              {t("translation:trend_lines")}
            </Select.Option>
          )}
        </Select>
      </div>
      {selectedOption === "datasets" && <Datasets widget={widget} />}
      {selectedOption === "trendlines" && <TrendLines widget={widget} />}
    </div>
  );
}
