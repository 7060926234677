import React, { useState, useEffect, useCallback } from "react";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col, Button } from "antd";
import {
  updateObjectInStateArray,
  deleteObjectInStateArray,
  addObjectInStateArray,
} from "/app/src/helpers/modifyObjectInStateArray";
import { integrationService, reportColumnTypeService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration, ReportColumnType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { themes, tables } from "/app/src/constants/themes";
import { useQuery } from "@tanstack/react-query";

export default function CustomColumnTypes() {
  const [columnTypes, setColumnTypes] = useState<ReportColumnType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    reportColumnTypeService
      .getAll(buildParams({ custom: "[or]custom;variance;Custom" }))
      .then((response) => setColumnTypes(response.report_column_types))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const addReportColumnType = (columnType: ReportColumnType) => {
    return addObjectInStateArray(
      columnType,
      columnTypes,
      setColumnTypes,
      reportColumnTypeService.createSingle,
    );
  };

  const removeColumnType = (columnType: ReportColumnType) => {
    return deleteObjectInStateArray(
      columnType,
      columnTypes,
      setColumnTypes,
      reportColumnTypeService.deleteSingle,
    );
  };

  const updateColumnType = (
    columnTypeId: number,
    columnType: ReportColumnType,
  ) => {
    return updateObjectInStateArray(
      columnTypeId,
      columnType,
      columnTypes,
      setColumnTypes,
      reportColumnTypeService.updateSingle,
    );
  };
  return (
    <div className="box">
      <h1>{t("translation:report_column_types")}</h1>
      <Row>
        <Col span={3}>
          <h3 className="">{t("translation:name")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:theme")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:table")}</h3>
        </Col>
        <Col span={4}>
          <h3>{t("translation:column")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:custom")}</h3>
        </Col>
        <Col span={3}>
          <h3>{t("translation:integration")}</h3>
        </Col>
      </Row>
      {columnTypes.map((columnType) => (
        <ColumnType
          columnType={columnType}
          key={columnType.id}
          removeColumnType={removeColumnType}
          updateColumnType={updateColumnType}
        />
      ))}
      <NewColumnType addReportColumnType={addReportColumnType} />
    </div>
  );
}

interface FormValues {
  name: string | undefined;
  baseTable: string | undefined;
  table: string | undefined;
  custom: string | undefined;
  tableColumn: string | undefined;
  integrationId: number | undefined;
}

export function ColumnType({
  columnType,
  removeColumnType,
  updateColumnType,
}: {
  columnType: ReportColumnType;
  removeColumnType: (columnType: ReportColumnType) => Promise<any> | undefined;
  updateColumnType: (
    columnTypeId: number,
    columnType: ReportColumnType,
  ) => Promise<any>;
}) {
  const { t } = useTranslation();
  const { data: integrations } = useQuery({
    queryKey: ["integrations"],
    queryFn: () => {
      return integrationService.getAll(buildParams({ orderby: "name" }));
    },
    initialData: { integrations: [] },
    select: (data: { integrations: Integration[] }) => {
      return data.integrations;
    },
  });

  const removeColumnTypeHandler = useCallback(() => {
    removeColumnType(columnType);
  }, [columnType, removeColumnType]);
  const columnTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={3}>
              <Form.Item name="name">
                <Input size="large" name="name" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="baseTable">
                <Select
                  size="large"
                  name="baseTable"
                  options={themes.map((theme) => ({
                    label: theme.name,
                    value: theme.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="table">
                <Select
                  size="large"
                  name="table"
                  options={tables.map((table) => ({
                    label: table.name,
                    value: table.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="tableColumn">
                <Input size="large" name="tableColumn" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="custom">
                <Input size="large" name="custom" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="integrationId">
                <Select size="large" name="integrationId">
                  {integrations?.map((integrations) => (
                    <Select.Option
                      key={integrations.id}
                      value={integrations.id}
                    >
                      {integrations.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton disabled={!dirty} type="primary" size="large" block>
                {t("translation:save")}
              </SubmitButton>
            </Col>
            <Col span={3}>
              <Button
                onClick={removeColumnTypeHandler}
                type="default"
                size="large"
                block
              >
                {t("translation:remove")}
              </Button>
            </Col>
          </Row>
        </Form>
      ),
      [integrations, removeColumnTypeHandler, t],
    );
  return (
    <div className="columnTypes">
      <Formik
        component={columnTypeForm}
        enableReinitialize
        initialValues={{
          name: columnType.name,
          table: columnType.table,
          tableColumn: columnType.tableColumn,
          baseTable: columnType.baseTable,
          custom: columnType.custom,
          integrationId: columnType.integrationId,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "baseTable", type: "string", required: true },
          { name: "table", type: "string", required: true },
          { name: "custom", type: "string", required: true },
        ])}
        onSubmit={(values, actions) => {
          if (columnType?.id) {
            updateColumnType(columnType.id, values).then((response) => {
              actions.setSubmitting(false);
              if (!response?.errors) {
                actions.resetForm();
              }
            });
          }
        }}
      />
    </div>
  );
}

export function NewColumnType({
  addReportColumnType,
}: {
  addReportColumnType: (columnType: ReportColumnType) => Promise<any>;
}) {
  const { t } = useTranslation();

  const { data: integrations } = useQuery({
    queryKey: ["integrations"],
    queryFn: () => {
      return integrationService.getAll(buildParams({ orderby: "name" }));
    },
    initialData: { integrations: [] },
    select: (data: { integrations: Integration[] }) => {
      return data.integrations;
    },
  });

  const initialFormValues: FormValues = {
    custom: "Custom",
    name: "",
    baseTable: "",
    table: "",
    tableColumn: "",
    integrationId: undefined,
  };
  const newMappingForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <Form.Item name="name" hasFeedback={false}>
                <Input
                  size="large"
                  name="name"
                  placeholder={t("translation:name")}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="baseTable">
                <Select
                  size="large"
                  name="baseTable"
                  placeholder={t("translation:theme")}
                  options={themes.map((theme) => ({
                    label: theme.name,
                    value: theme.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="table">
                <Select
                  size="large"
                  name="table"
                  placeholder={t("translation:table")}
                  options={tables.map((table) => ({
                    label: table.name,
                    value: table.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="tableColumn">
                <Input
                  size="large"
                  name="tableColumn"
                  placeholder={t("translation:column")}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="custom" hasFeedback={false}>
                <Input size="large" name="custom" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="integrationId" hasFeedback={false}>
                <Select
                  size="large"
                  name="integrationId"
                  placeholder={t("translation:integration")}
                >
                  {integrations?.map((integrations) => (
                    <Select.Option
                      key={integrations.id}
                      value={integrations.id}
                    >
                      {integrations.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton disabled={!dirty} type="primary" size="large" block>
                {t("translation:new_column_type")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [integrations, t],
    );
  return (
    <div className="newMapping">
      <Formik
        component={newMappingForm}
        initialValues={initialFormValues}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "baseTable", type: "string", required: true },
          { name: "table", type: "string", required: true },
          { name: "custom", type: "string", required: true },
        ])}
        onSubmit={(values, actions) => {
          addReportColumnType(values).then((response) => {
            if (!response?.errors) {
              actions.resetForm();
            }
          });
        }}
      />
    </div>
  );
}
