import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";
import { settingService, statusService } from "/app/src/services";
import { getTimeZones } from "/app/src/helpers/time";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { mapTimeZones } from "/app/src/components/generic/formatting/timeZone";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * Component to display the forms for updating the time zone
 */
export default function TimeZone() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  //useQuery for getting the time zone
  const { data: timeZone } = useQuery({
    queryKey: ["timeZone"],
    queryFn: () => {
      return statusService.getStatus();
    },
    initialData: { timeZone: "" },
    select: (data: { timeZone: string }) => {
      return data.timeZone;
    },
  });
  //useMutation for updating the time zone
  const { mutateAsync: updateTimezone } = useMutation({
    mutationFn: (values: { timeZone: string }) => {
      return settingService
        .updateSettings({ timeZone: values.timeZone })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.timeZone = values.timeZone;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["timeZone"], { timeZone: data.timeZone });
    },
  });

  //onSubmit handler for updating the time zone
  const onSubmitTimezone = useCallback(
    async (
      { value: timeZone }: { value: string },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      await updateTimezone({ timeZone }).catch((error) => {
        return setFieldError("timeZone", error.errors[0]);
      });
    },
    [updateTimezone],
  );

  /**
   * Update time zone form
   */
  const updateTimeZoneForm: (
    props: FormikProps<{ value: string }>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3>{t("translation:Time_Zone")}</h3>
            <p>{t("translation:time_zone_description")}</p>
          </Col>
          <Col span={13}>
            <Form.Item name="value" label={t("translation:Time_Zone")}>
              <SearchFilter
                list={getTimeZones()}
                name="value"
                placeholder={t("translation:select_time_zone")}
                sort
                mapOptionsFn={mapTimeZones}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              className="no-label" // skipcq: JS-0394
              type="primary"
              size="large"
              block
              disabled={!dirty}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  return (
    <Formik
      component={updateTimeZoneForm}
      initialValues={{
        value: timeZone,
      }}
      enableReinitialize
      onSubmit={onSubmitTimezone}
    />
  );
}
