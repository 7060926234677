import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";

export default function Controls() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="controls">
      <Row>
        <Col span={8}>
          <h1>{t("translation:all_users")}</h1>
        </Col>

        <Col span={16}>
          <div className="controlButtons">
            <Button
              type="primary"
              onClick={() => {
                navigate("/users/new");
              }}
            >
              {t("translation:new_user")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
