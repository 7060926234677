import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Row, Col, message } from "antd";
import { userService } from "/app/src/services";
import { userResetSchema } from "/app/src/schemas";
import { useAuthState } from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";

interface FormValues {
  search: string;
}

export default function ForgotForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthState();
  // redirect to home if already logged in
  useEffect(() => {
    if (user?.id) {
      navigate("/");
    }
  }, [user, navigate]);
  const success = () => {
    message.success(t("translation:password_reset_email"));
  };

  const ResetForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ errors, touched, isValid, dirty }) => (
        <Form>
          <Form.Item name="search">
            <Input
              suffix
              name="search"
              placeholder={t("translation:reset_email_tip")}
              className={
                errors.search && touched.search ? "input-error" : undefined
              }
              size="large"
            />
          </Form.Item>
          <SubmitButton
            type="primary"
            block
            size="large"
            disabled={!(dirty && isValid)}
          >
            {t("translation:send_reset_email")}
          </SubmitButton>
        </Form>
      ),
      [t],
    );

  return (
    <div className="login">
      <Helmet>
        <title>
          {t("translation:forgot")} {t("translation:your_password")} - ItemPath
        </title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 8, offset: 8 }}
        >
          <div className="box mt-4">
            <h3 style={{ textAlign: "center", margin: "20px 0 30px" }}>
              {t("translation:reset_link_sent")}
            </h3>
            <Formik
              component={ResetForm}
              initialValues={{
                search: "",
              }}
              validationSchema={userResetSchema}
              onSubmit={({ search }, actions) => {
                const path = `${window.location.protocol}//${window.location.host}`;
                userService
                  .reset({ search: search, path: path })
                  .then((response) => {
                    success();
                  })
                  .catch((error) => {
                    //success should show whether or not there is a user found with the given value
                    success();
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
