import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";
import { integrationService } from "/app/src/services";
import { useMutation } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  name: string;
  number: string;
}

function formatForm(
  values: FormValues,
  appId: number | undefined,
): Integration {
  return {
    appId,
    name: values.name,
    number: values.number,
  };
}

export default function NewIntegration({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: addIntegration } = useMutation({
    mutationFn: (integration: Integration) => {
      return integrationService
        .createSingle(integration)
        .then(handlePromiseError);
    },
  });

  const addIntegrationHandler = useCallback(
    async (values: FormValues) => {
      const integration = formatForm(values, app.id);
      await addIntegration(integration).then((response) => {
        const id = response.integration.id;
        if (app.id) {
          navigate(`/apps/${app.id}/displays/${id}`);
        }
      });
    },
    [addIntegration, app.id, navigate],
  );

  const newIntegrationForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={7}>
              <Form.Item
                name="baseTable"
                label={`${t("translation:display")} ${t("translation:type")}`}
              >
                <Select name="number" size="large" defaultValue={1}>
                  <Select.Option value={1}>
                    {`${t("translation:storage_unit")} ${t(
                      "translation:load",
                    )}`}
                  </Select.Option>
                  <Select.Option value={2}>
                    {t("translation:divert_tool")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("translation:available_apps")}
                hasFeedback={false}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_license")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={1} />

            <Col span={4}>
              <SubmitButton type="primary" size="large" block>
                {t("translation:create")} {t("translation:display")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <div className="box">
      <div className="newIntegration">
        <Formik
          component={newIntegrationForm}
          initialValues={{
            name: "",
            number: "",
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={addIntegrationHandler}
        />
      </div>
    </div>
  );
}
