import { object, string, array } from "yup";
import isIpAddress from "/app/src/helpers/validators";
import i18next from "/app/src/locales/config";

export const newConnectionSchema = object().shape({
  name: string().required(i18next.t("translation:name_is_required")),
  type: array().required(i18next.t("translation:type_is_required")),
  username: string().when("type", {
    is: (type) =>
      type?.includes("MSSQL") || type.includes("Basic") || type.includes("PLC"),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  password: string().when("type", {
    is: (type) =>
      type?.includes("MSSQL") || type.includes("Basic") || type.includes("PLC"),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
  url: string()
    .required(i18next.t("translation:address_is_required"))
    .test(
      "translation:test-name",
      i18next.t("translation:valid_address"),
      (value) => {
        return isIpAddress(value);
      },
    ),
  token: string().when("type", {
    is: (type) => type?.includes("MSSQL"),
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
});
